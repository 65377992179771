import React, { useEffect, useMemo, useRef, useState } from "react"
import classes from "./Suggestions.module.css"
import TickIcon from "../../../components/UI/svgComponents/TickIcon"
import remove from "../../../assets/image/svg/remove.svg"
import addcart from "../../../assets/image/svg/add to cart.svg"
import {
  Paper,
  List,
  ListItem,
  Autocomplete,
  debounce,
  TextField,
} from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import productPlaceHolder from "../../../assets/image/png/products_placeholder@2x.png"
import Selecter from "../../../components/UI/Dropdown/Select"
import {
  setSearchTerm,
  setCartData,
  clearSuggestions,
  removeFromCart,
  clearAllData,
  setMaterialValue,
  setFinish,
  fetchList,
  fetchRemoveList,
  setCategory,
  setSubCategory,
  setSizeValue,
  setSelectedSizeName,
} from "../../../services/storeSlice/shopNowSlice"
import useApiHttp from "../../../hooks/ues-http"
import CustomeSlecter from "../../../components/UI/Dropdown/CustomeSlecter"
import Fuse from "fuse.js"

const SuggestionsInStock = ({ data }) => {
  
  const dispatch = useDispatch()
  const summaryData = useSelector(state => state.shopNow)
  const materialAndFinish = summaryData.materialAndFinish
  let cartData = summaryData?.cartData?.cart_items

  const [metaData, setMetaData] = useState()
  const [materials, setMaterials] = useState([])
  const [coatings, setMCoatings] = useState([])
  const [materialsMeta, setMaterialsMeta] = useState([])
  const [coatingsMeta, setCoatingsMeta] = useState([])
  const [sizeList, setSizeList] = useState([])

  const containerRef = useRef(null)

  const [categories, setCategories] = useState([])
  const [subcategories, setSubcategories] = useState([])

  const [datas, setDatas] = useState([])
  const [typingValue, setTypingValue] = useState("")
  const [searchResults, setSearchResults] = useState([])

  const {
    isLoading: addCardDataLoading,
    success: addCardDataSucces,
    error: addCardDataError,
    sendRequest: addCardDataRequest,
  } = useApiHttp()

  const {
    isLoading: sizeListLoading,
    success: sizeListSucces,
    error: sizeListError,
    sendRequest: sizeListRequest,
  } = useApiHttp()

  const {
    isLoading: materialsMetaLoading,
    success: materialsMetaSucces,
    error: materialsMetaError,
    sendRequest: materialsMetaRequest,
  } = useApiHttp()
  const {
    isLoading: coatingsMetaLoading,
    success: coatingsMetaSucces,
    error: coatingsMetaError,
    sendRequest: coatingsMetaRequest,
  } = useApiHttp()

  var searchValue =
    summaryData.searchTerm ||
    summaryData.searchbyitemcode ||
    summaryData.searchByAllData ||
    ""

  const addCardHandleSelect = token => {
    addCardDataRequest(
      {
        url: `dealer/shop-now/cart/add`,
        method: "POST",
        body: {
          product_token: token,
          search_value: searchValue,
        },
      },
      data => {
        dispatch(fetchList())
      }
    )
  }

  const {
    isLoading: metaDataLoading,
    success: metaDataSucces,
    error: metaDataError,
    sendRequest: metaDataRequest,
  } = useApiHttp()

  useEffect(() => {
    metaDataRequest(
      {
        url: `dealer/shop-now/category-drop-down`,
      },
      data => {
        setMetaData(data?.data || {}) // Ensure data is safely handled
      }
    )
  }, [])

  useEffect(() => {
    if (metaData?.materials) {
      setMaterials(metaData.materials)
    }
    if (metaData?.coatings) {
      setMCoatings(metaData.coatings)
    }
    if (metaData?.categories) {
      setCategories(metaData.categories)
    }
  }, [metaData])

  useEffect(() => {
    if (summaryData.selectedCategory && summaryData.selectedSubCategory) {
      materialsMetaRequest(
        {
          url: `dealer/shop-now/instock-material/list`,
          method: "POST",
          body: {
            category_token: summaryData.selectedCategory,
            sub_category_token: summaryData.selectedSubCategory,
          },
        },
        data => {
          setMaterialsMeta(data?.data || {}) // Ensure data is safely handled
        }
      )
    }
  }, [summaryData.selectedCategory, summaryData.selectedSubCategory])

  useEffect(() => {
    if (
      summaryData.selectedCategory &&
      summaryData.selectedSubCategory &&
      summaryData.slectmaterial
    ) {
      coatingsMetaRequest(
        {
          url: `dealer/shop-now/instock-finish/list`,
          method: "POST",
          body: {
            category_token: summaryData.selectedCategory,
            sub_category_token: summaryData.selectedSubCategory,
            material_token: summaryData.slectmaterial,
          },
        },
        data => {
          setCoatingsMeta(data?.data || {}) // Ensure data is safely handled
        }
      )
    }
  }, [
    summaryData.selectedCategory,
    summaryData.selectedSubCategory,
    summaryData.slectmaterial,
  ])

  const sizeListRequestHandle = () => {
    sizeListRequest(
      {
        url: `dealer/shop-now/instock-size/list`,
        method: "POST",
        body: {
          category_token: summaryData.selectedCategory,
          sub_category_token: summaryData.selectedSubCategory,
          material_token: summaryData.slectmaterial,
          coating_token: summaryData.slectfinish,
        },
      },
      data => {
        setSizeList(data?.data)
      }
    )
  }

  useEffect(() => {
    if (summaryData.slectmaterial && summaryData.slectfinish) {
      sizeListRequestHandle()
    }
  }, [
    summaryData.selectedCategory,
    summaryData.selectedSubCategory,
    summaryData.slectmaterial,
    summaryData.slectfinish,
  ])

  // Handle category change
  const handleCategoryChange = categoryToken => {
    // setSelectedCategory(categoryToken)
    dispatch(setSubCategory(""))
    dispatch(setMaterialValue(""))
    dispatch(setFinish(""))
    dispatch(setSizeValue(null))
    dispatch(setSelectedSizeName(null))
    dispatch(setCategory(categoryToken))
    if (categoryToken === " ") {
      dispatch(clearSuggestions())
    }

    const category = categories?.find(cat => cat.token === categoryToken)
    if (category) {
      setSubcategories(category?.subcategory || [])
    } else {
      setSubcategories([])
      dispatch(setSubCategory(""))
    }
    // setSelectedSubCategory("")
  }

  // Handle subcategory change
  const handleSubCategoryChange = subCategoryToken => {
    dispatch(setSubCategory(subCategoryToken))
    if (subCategoryToken === " ") {
      dispatch(clearSuggestions())
    }
  }

  // Handle subcategory change
  const handleMaterialChange = material => {
    dispatch(setMaterialValue(material))
  }
  // Handle subcategory change
  const handleSubFinishChange = finish => {
    dispatch(setFinish(finish))
  }

  const removeCartIteme = token => {
    dispatch(fetchRemoveList({ body: { cart_item_token: token } }))
      .unwrap()
      .then(() => {
        dispatch(fetchList())
      })
  }

  // ... other hooks and functions

  useEffect(() => {
    const handleClickOutside = event => {
      if (containerRef.current && containerRef.current.contains(event.target)) {
        dispatch(clearAllData()) // Or any other logic to close/hide the suggestions
        // dispatch(reRenderStockProducts())
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [dispatch])

  // Flatten and initialize size data
  useEffect(() => {
    const flattenedSizes = sizeList.map(item => ({
      token: item.token || "",
      name: item.name || "no data",
    }))
    setDatas(flattenedSizes)
    setSearchResults(flattenedSizes.slice(0, 200)) // Initial 200 items
  }, [sizeList])

  // Initialize Fuse.js for searching
  const fuse = useMemo(() => {
    return new Fuse(datas, {
      keys: ["name"],
      threshold: 0.3,
      shouldSort: true,
    })
  }, [datas])

  // Debounced input change handler
  const handleInputChange = debounce(inputValue => {
    if (inputValue) {
      const results = fuse.search(inputValue).map(result => result.item)
      const strictFilteredResults = results.filter(oldResult =>
        oldResult.name.toLowerCase().startsWith(inputValue.toLowerCase())
      )

      setSearchResults(strictFilteredResults)
    } else {
      setSearchResults(datas.slice(0, 200)) // Reset if cleared
    }
  }, 300)

  // Memoized list rendering
  const renderedSuggestions = useMemo(() => {
    return data.map((item, index) => (
      <ListItem
        key={index}
        button
        sx={{ borderBottom: "1px solid var(--light-outline)" }}
      >
        <div className={classes.box}>
          <div className={classes.box_left}>
            <div className={classes.side_box}>
              <img
                src={item?.url ? item?.url : productPlaceHolder}
                alt="product"
                style={{ width: "50px", height: "50px" }}
              />
              <div className={classes.side_box_contant}>
                <h3>
                  {item?.product_code} - <span>{item?.variant}</span>
                </h3>
                <p>{`${item?.name}`}</p>
                <p>
                  {" "}
                  {`${item?.category} | ${item?.sub_category} | ${item?.coating} | ${item.material} | ${item.vertical} | ${item.size} |`}{" "}
                  <span className="moq_color_black">MOQ {item.moq}</span>
                </p>
              </div>
            </div>
          </div>
          <div className={classes.box_right}>
            <label
              className={`label_widgets ${
                item.stock_status === 1 ? "in_stock" : "out_stock"
              }`}
            >
              {item.stock_status === 1 ? (
                <span>{item.inventory_quantity} In Stock</span>
              ) : (
                "Out of Stock"
              )}
            </label>
            <label className="rate_box">
              <span className="count">₹ {item.item_rate}</span>
              {item.item_rate !== item.mrp && (
                <div>
                  <span className="line_through_price">₹ {item.mrp}</span>
                  {/* <p className="discount_percentage">
                    (
                    {(((item.mrp - item.item_rate) / item.mrp) * 100).toFixed(
                      2
                    )}
                    %)
                  </p> */}
                </div>
              )}
            </label>

            {cartData?.find(x => x.product_token === item.token) ? (
              <>
                <span className="added_cart">
                  <TickIcon />
                  Added to Cart
                </span>
                <span
                  className="cursor iconaddcart"
                  aria-label="Remove from cart"
                  onClick={() =>
                    removeCartIteme(
                      cartData?.find(x => x.product_token === item.token)
                        ?.cart_item_token
                    )
                  }
                >
                  <img src={remove} alt="Remove from cart" />
                </span>
              </>
            ) : (
              <span
                className="cursor iconaddcart"
                aria-label="Add to cart"
                onClick={() => addCardHandleSelect(item.token)}
              >
                <img src={addcart} alt="Add to cart" />
              </span>
            )}
          </div>
        </div>
      </ListItem>
    ))
  }, [data, cartData, summaryData, materialAndFinish]) // Only recompute when `data` changes

  return (
    <div className={classes.suggestion_box}>
      <div className={classes.suggestion_result}>
        <div className={classes.suggestion_result_right}>
          <p>{summaryData.stockResultsTotal} Results</p>
          <div>
            <CustomeSlecter
              data={
                categories?.length > 0
                  ? categories.map(category => ({
                      label: category.name,
                      value: category.token,
                    }))
                  : [] // Provide an empty array as fallback
              }
              title={"Category"}
              width={150}
              value={summaryData.selectedCategory}
              onChange={e => handleCategoryChange(e.target.value)}
            />
            <CustomeSlecter
              data={
                subcategories?.length > 0
                  ? subcategories.map(sub => ({
                      label: sub.name,
                      value: sub.token,
                    }))
                  : [] // Provide an empty array as fallback
              }
              title={"Sub-Category"}
              width={180}
              value={summaryData.selectedSubCategory}
              onChange={e => handleSubCategoryChange(e.target.value)}
              disabled={!summaryData.selectedCategory}
            />
            <CustomeSlecter
              data={
                materialsMeta?.length > 0
                  ? materialsMeta.map(sub => ({
                      label: sub.name,
                      value: sub.token,
                    }))
                  : [] // Provide an empty array as fallback
              }
              title={"Material"}
              width={130}
              value={summaryData.slectmaterial}
              onChange={e => handleMaterialChange(e.target.value)}
              disabled={
                !summaryData.selectedCategory ||
                !summaryData.selectedSubCategory
              }
            />
            <CustomeSlecter
              data={
                coatingsMeta?.length > 0
                  ? coatingsMeta.map(sub => ({
                      label: sub.name,
                      value: sub.token,
                    }))
                  : [] // Provide an empty array as fallback
              }
              title={"Variant"}
              width={130}
              value={summaryData.slectfinish}
              onChange={e => handleSubFinishChange(e.target.value)}
              disabled={
                !summaryData.selectedCategory ||
                !summaryData.selectedSubCategory ||
                !summaryData.slectmaterial
              }
            />
          </div>
        </div>
        <div style={{ width: "200px" }}>
          <Autocomplete
            disablePortal
            options={searchResults}
            loading={sizeListLoading}
            getOptionLabel={option => option.name}
            isOptionEqualToValue={(option, value) =>
              option.token === value.token
            }
            value={summaryData.selectedSizeName}
            onChange={(event, newValue) => {
              dispatch(setSizeValue(newValue?.token || null))
              dispatch(setSelectedSizeName(newValue))
            }}
            onInputChange={(event, inputValue) => {
              setTypingValue(inputValue)
              handleInputChange(inputValue)
            }}
            ListboxProps={{
              className: "custom-autocomplete-listbox", // Add a custom class
            }}
            renderInput={params => (
              <TextField
                {...params}
                label={
                  !summaryData.selectedSizeName && !typingValue ? "Size" : ""
                }
                InputLabelProps={{
                  shrink: false,
                  style: {
                    fontSize:
                      summaryData.selectedSizeName || typingValue
                        ? "0px"
                        : "14px",
                    color: "#363636",
                    fontFamily: "var(--font-regular)",
                  },
                }}
                disabled={
                  !summaryData.selectedCategory ||
                  !summaryData.selectedSubCategory ||
                  !summaryData.slectmaterial ||
                  !summaryData.slectfinish
                }
              />
            )}
            sx={{
              fontSize: "14px",
              color: "#363636",
              fontFamily: "var(--font-Medium)",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              boxShadow: "none",
              opacity:
                !summaryData.selectedCategory ||
                !summaryData.selectedSubCategory ||
                !summaryData.slectmaterial ||
                !summaryData.slectfinish
                  ? 0.5
                  : 1,
              pointerEvents:
                !summaryData.selectedCategory ||
                !summaryData.selectedSubCategory ||
                !summaryData.slectmaterial ||
                !summaryData.slectfinish
                  ? "none"
                  : "auto",
              cursor:
                !summaryData.selectedCategory ||
                !summaryData.selectedSubCategory ||
                !summaryData.slectmaterial ||
                !summaryData.slectfinish
                  ? "not-allowed"
                  : "pointer",
            }}
          />
        </div>
        {/* <img
          src={remove}
          alt="Remove from cart"
          className={classes.suggestion_remove}
          ref={containerRef}
        /> */}
      </div>
      <Paper
        elevation={3}
        className={classes.suggestion_paper}
        style={{
          maxWidth: "100%",
          marginTop: "5px",
          position: "relative",
          zIndex: 50,
          marginBottom: "20px",
          maxHeight: "384px",
          overflow: "auto",
        }}
      >
        {!materialAndFinish ? (
          <List>{renderedSuggestions}</List>
        ) : (
          <div
            style={{
              textAlign: "center",
              background: "#fff",
              marginTop: ".5rem",
              padding: " 3px",
              borderRadius: "5px",
            }}
          >
            No Data
          </div>
        )}
      </Paper>
    </div>
  )
}

export default SuggestionsInStock
