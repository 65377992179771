import React, { useEffect, useState, useRef } from 'react'
import { Box, Button, Divider, FormControl, InputLabel, MenuItem, Modal, Paper, Select, Stack, Typography } from "@mui/material";
import classes from "./inventory.module.css";
import CustomeSlecter from "../../components/UI/Dropdown/CustomeSlecter";
import CustomButton from "../../components/UI/Button/Button";
// import downloadIcon from '../../../assets/image/svg/download.svg'
import searchIcon from '../../assets/image/svg/order_search.svg'
import CustomDataGrid from "../../components/UI/customDataGrid/CustomDataGrid";
import InventoryServices from "./inventory.service";
import styled from "styled-components"
import ViewModal from '../../components/UI/viewModal/ViewModal';
import UploadIcon from '../../components/UI/svgComponents/UploadIcon';
import tick from "../../assets/image/svg/tick.svg"
import Qty from '../../components/UI/Qty/Qty';
import DownloadIcon from '../../components/UI/svgComponents/downloadIcon';


const StatusWidgetLable = styled.div.attrs(props => ({ className: classes[props.class] }))`
display:inline-block;
margin-bottom:10px;
height:24px;
font-family:var(--font-regular);
color:#FFFFFF;
line-height:14px;
font-size:14px;
padding: 4px 10px;
border-radius: 3px;
`

const InventoryManagement = () => {
  const { FetchCategories, FetchSubCategories, FetchProducts, DownloadTable, UpdateSingleProductStock, UpdateProductStockCsv } = InventoryServices();
  const { sendRequest: getCategories } = FetchCategories();
  const { sendRequest: getSubCategories } = FetchSubCategories();
  const { sendRequest: getProducts } = FetchProducts();
  const { sendRequest: updateProductStock } = UpdateSingleProductStock();
  const { sendRequest: uploadStockCsv } = UpdateProductStockCsv();
  const [categoriesDet, setCategoriesDet] = useState({ categories_list: [], selectedVal: "" });
  const [subCategoriesDet, setSubCategoriesDet] = useState({ sub_categories_list: [], selectedVal: "" });
  const [hasValueChanged, sethasValueChanged] = useState(false);
  const [generateTable, setgenerateTable] = useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [stockUpdateType, setStockUpdateType] = useState('single');
  const [selectedFileObject, setselectedFileObject] = React.useState("");
  const [selectedFileError, setselectedFileError] = React.useState("");
  const [qty, setQty] = React.useState("");
  const [selectedProduct, setSelectedProduct] = React.useState(null);



  useEffect(() => {
    getCategories({}, (data) => {
      setCategoriesDet((curr) => ({ ...curr, categories_list: data.data.map((cat) => ({ value: cat.token, label: cat.name })) }))

    })
  }, []);

  useEffect(() => {
    getSubCategories({
      body: {
        "category_token": categoriesDet.selectedVal
      }
    }, (data) => {
      setSubCategoriesDet({ selectedVal: "", sub_categories_list: data.data.map((subCat) => ({ value: subCat.token, label: subCat.name })) })

    })
  }, [categoriesDet.selectedVal]);

  useEffect(() => {
    sethasValueChanged(true);
  }, [categoriesDet.selectedVal, subCategoriesDet.selectedVal]);

  const handleGenerateClick = () => {
    if (hasValueChanged) {
      setgenerateTable((prev) => !prev)
      sethasValueChanged(false);
    }
  }


  const productsColumn = [
    {
      field: "si_no",
      headerName: "S.No",
      sortable: false,
      width: 70,
    },
    {
      field: "product_code",
      headerName: "Product Id",
      sortable: true,
      width: 120,
      // renderCell: (params) => (
      //   <span  className={classes.product_code_text}>
      //     {params.formattedValue}
      //   </span>
      // )
    },
    {
      field: "variant",
      headerName: "Variant",
      sortable: false,
      width: 90,
    },
    {
      field: "vertical",
      headerName: "Vertical",
      sortable: false,
      width: 200,
    },
    {
      field: "product_name",
      headerName: "Product Description",
      sortable: false,
      width: 300,
    },
    {
      field: "size",
      headerName: "Size",
      sortable: false,
      width: 150,
    },
    {
      field: "category_name",
      headerName: "Category",
      sortable: false,
      width: 200,
    },
    {
      field: "sub_category_name",
      headerName: "Sub-Category",
      sortable: false,
      width: 250,
    },
    {
      field: "type",
      headerName: "Type",
      sortable: true,
      width: 150,
    },
    {
      field: "material",
      headerName: "Material",
      sortable: true,
      width: 150,
    },
    {
      field: "coating",
      headerName: "Coating",
      sortable: true,
      width: 150,
    },
    {
      field: "job_cat",
      headerName: "Job Category",
      sortable: true,
      width: 150,
    },
    {
      field: "job_sub_cat",
      headerName: "Job Sub-Category",
      sortable: true,
      width: 150,
    },
    {
      field: "moq",
      headerName: "MOQ",
      sortable: true,
      width: 150,
    },
    {
      field: "mrp",
      headerName: "Item MRP",
      sortable: true,
      width: 150,
    },
    {
      field: "amount",
      headerName: "Item Rate",
      sortable: true,
      width: 150,
      // renderCell: (params) => {
      //   const parsedDate = new Date(params?.row.applied_date);
      //   const day = parsedDate.getDate().toString().padStart(2, "0");
      //   const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
      //   const year = parsedDate.getFullYear();
      //   return `${day}/${month}/${year}`;
      // },
      // renderCell: (params) => dateConvert(params?.row.applied_date),
    },
    {
      field: "qty",
      headerName: "Net Stock",
      sortable: true,
      width: 100,
      // renderCell: (params) => {
      //   const parsedDate = new Date(params?.row.applied_date);
      //   const day = parsedDate.getDate().toString().padStart(2, "0");
      //   const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
      //   const year = parsedDate.getFullYear();
      //   return `${day}/${month}/${year}`;
      // },
      // renderCell: (params) => dateConvert(params?.row.applied_date),
    },
    {
      field: "available",
      headerName: "Availability",
      sortable: false,
      width: 110,
      renderCell: (params) => (
        <StatusWidgetLable class={params.row.status_color}>
          {params.formattedValue}
        </StatusWidgetLable>
      )
    },
    {
      // field: "available",
      headerName: "Action",
      sortable: false,
      width: 110,
      renderCell: (params) => (
        <span
          onClick={() => {
            setSelectedProduct({
              token: params.row.token,
              id: params.row.product_code,
              name: params.row.product_name,
              current_qty: params.row.qty
            });
            setStockUpdateType('single');
            setModalOpen('true');
          }}
          className={classes.product_code_text}>
          Update Stock
        </span>
      )
    },

  ];

  const handleFileChange = e => {
    const file = e.target.files[0] // Get the first selected file
    // return

    if (file) {
      // Allowed types: jpeg, png, jpg, pdf
      const allowedTypes = [

        "text/csv",
      ]

      // Check if the file type is allowed
      if (!allowedTypes.includes(file.type)) {
        setselectedFileError(
          "Invalid file type! Please select a CSV."
        )
        setselectedFileObject(null)
        // setImagePreview("")
        return
      }

      // Check if the file size is less than or equal to 2MB
      const maxSize = 3 * 1024 * 1024 // 2MB in bytes
      if (file.size > maxSize) {
        setselectedFileError("File size exceeds 3MB! Please select a smaller file.")
        setselectedFileObject(null)
        // setImagePreview("")
        return
      }

      // If everything is valid, set the file and clear the error
      setselectedFileObject(file)
      setselectedFileError("")

      // If it's an image, create a preview
      // if (file.type.startsWith("image/")) {
      //   const reader = new FileReader()
      //   reader.onload = e => {
      //     setImagePreview(e.target.result) // Set the preview URL
      //   }
      //   reader.readAsDataURL(file)
      // } else {
      //   // If it's a PDF, clear any previous image preview
      //   setImagePreview("pdf")
      // }
    }
  }

  const RenderModalContent = () => {
    return stockUpdateType === 'bulk' ? (
      <Box mt={3}>
        <p style={{ color: "#1514145e", fontSize: "12px", fontFamily: "var(--font-regular)" }}>Upload Net Stock CSV File</p>


        <Box className={classes.upload_container}>
          {!selectedFileObject && (
            <label htmlFor='stocks-csv' style={{ display: "inline-block", cursor: "pointer", textAlign: "center", fontSize: "12px", fontFamily: "var(--font-regular)" }}>
              <UploadIcon />
              <p>Upload Your CSV file</p>
              {selectedFileError && (
                <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                  {selectedFileError}
                </Typography>
              )}
            </label>
          )}

          {selectedFileObject && (
            <div className={classes.pdfPreview}>
              <img
                src={tick} // Use a PDF icon if available
                alt="CSV Preview"
                style={{
                  width: "60px",
                  maxWidth: "200px",
                  height: "auto",
                }}
              />
              <p>{selectedFileObject.name}</p>
              <p>File Loaded Successfully.</p>
              <label style={{ fontSize: "12px", fontFamily: "var(--font-regular)", cursor: "pointer", color: "#1976d2" }} htmlFor='stocks-csv'>Change</label>
            </div>
          )}

        </Box>
        <input style={{ display: "none" }} type='file' onChange={handleFileChange} accept='.csv' id='stocks-csv' />
        <p style={{ marginTop: "6px", fontSize: "12px", fontFamily: "var(--font-regular)" }}>Note: CSV file should exactly match the format as prescribed in the sample CSV. Download Sample CSV below</p>
        <span style={{ textAlign: "center", marginTop: "10px" }}>
          <DownloadIcon />
          <a style={{ color: "#006FBE", fontSize: "14px", fontFamily: "var(--font-regular)", marginLeft: "4px" }} href={require('../../assets/csv/inventory_sample.csv')} download="inventory_stock_sample.csv">Download Sample CSV</a>
        </span>

      </Box>
    ) : (
      <Box mt={3}>
        <p style={{ color: "#212121", fontSize: "12px", fontFamily: "var(--font-regular)" }}>Update Stock Quantity for</p>
        <p style={{ color: "#212121", fontSize: "12px", fontFamily: "var(--font-regular)", marginTop: "8px" }}>{`${selectedProduct?.name} - ${selectedProduct?.id}`}</p>
        <p style={{ color: "#212121", fontSize: "12px", fontFamily: "var(--font-regular)" }}>{`Current Stock Qty - ${selectedProduct?.current_qty}`}</p>


        <Qty
          type={"text"}
          label={"Net Stock"}
          customStyle={{ marginTop: "10px" }}
          borderRadius={"7px"}
          onChange={(e) => {
            if (isNaN(Number(e.target.value))) {
              setQty("");
              return;
            };
            setQty(e.target.value)
          }}
          value={qty}
          width={"inherit"}
        />
        {selectedFileError && (
          <Typography color="error" variant="body2" sx={{ mt: 1 }}>
            {selectedFileError}
          </Typography>
        )}
      </Box>
    )
  }

  const RenderCta = () => {
    const cancelCta = () => {
      setselectedFileObject(null);
      setselectedFileError('');
      setSelectedProduct(null);
      setQty('')
      setModalOpen(false)

    }

    const onSubmitCta = () => {
      if (stockUpdateType === 'bulk') {

        if (selectedFileObject) {
          // let body = JSON.stringify({
          //   "order_token": acknowledgementStatusUpdateData.order_token,
          //   "acknowledgement_document": selectedFileObject,
          // })

          let body = new FormData();
          // body.append('order_token', acknowledgementStatusUpdateData.order_token);
          body.append('upload_stock_csv', selectedFileObject);
          uploadStockCsv({

            body: body
          }, (response) => {

            setgenerateTable((prev) => !prev);
            cancelCta();



          })


        } else {
          setselectedFileError('Upload CSV')
        }
      } else {
        if (qty === '') {
          setselectedFileError('Quantity cannot be empty')
        } else if (Number(qty) === 0) {
          setselectedFileError('Quantity cannot be 0')

        } else {
          let body = {
            "product_token": selectedProduct?.token,
            "qty": qty
          }
          updateProductStock({
            body: body
          }, (res) => {
            setgenerateTable((prev) => !prev);
            cancelCta();
          })
        }

      }

    }
    return (
      <>
        <Button sx={{ color: "grey", fontFamily: "var(--font-semibold)" }} onClick={cancelCta} className={classes.button_style}>Cancel</Button>
        <Button sx={{ fontFamily: "var(--font-semibold)" }} onClick={onSubmitCta} className={classes.button_style}>Submit</Button>

      </>

    )
  }


  const OptionalAdornment = () => (
    <Button
      onClick={() => {
        setStockUpdateType('bulk');
        setModalOpen('true');
      }}
      className={classes.buttonOngoing}
      sx={{
        backgroundColor: "#376fd0",
        color: "#fff",
        fontSize: "12px",
        textTransform: "capitalize",
        marginRight: "1rem",
        padding: "13px 20px",
        cursor: "pointer",
        borderRadius: "8px",
        width: "180px",
        minWidth: "50px",
        fontFamily: "var(--font-semibold)",
        fontSize: "small"
      }}
      variant="outlined"
    >
      Upload Stock CSV
    </Button>
  )

  return (
    <>
      <Stack>
        <h6 className={classes.header_text_class}>Inventory Master</h6>
        <Divider sx={{ marginTop: "16px", marginBottom: "35px" }} />
      </Stack>
      <Stack direction={"row"} gap={"10px"} width={"70%"} justifyContent={"flex-start"} flexWrap={"wrap"}>
        <CustomeSlecter
          data={categoriesDet.categories_list}
          title={"Category"}
          width={200}
          value={categoriesDet.selectedVal}
          selectSx={{
            borderRadius: "10px"
          }}
          onChange={e => setCategoriesDet((curr) => ({ ...curr, selectedVal: e.target.value }))}
        />
        <CustomeSlecter
          data={subCategoriesDet.sub_categories_list}
          title={"Sub-Category"}
          width={200}
          value={subCategoriesDet.selectedVal}
          selectSx={{
            borderRadius: "10px"
          }}
          onChange={e => setSubCategoriesDet((curr) => ({ ...curr, selectedVal: e.target.value }))}

        />
        <CustomButton
          customColor="white"
          customBgColor="#376FD0"
          custmstyle={{
            width: "170px",
            minWidth: "20%",
            borderRadius: "8px"
          }}
          onClick={handleGenerateClick}
        >
          Generate
        </CustomButton>
      </Stack>
      <Box sx={{ width: '100%', mt: 5 }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          {/* <div className={classes.table_header_container}>
              <p className={classes.table_header_title}>Products List</p>
              <div className={classes.table_header_total_search}>
                <p className={classes.table_header_sub_title}>164 Products</p>
                <div className={classes.icons_container}>
                  <img src={searchIcon} width={24} height={24} />
                  <div className={classes.divider}></div>
                      <img src={downloadIcon} width={24} height={24}/>
                </div>
              </div>
            </div> */}
          <CustomDataGrid
            adornmentType={["search", "download_menu"]}
            optionalAdornment={OptionalAdornment}
            otherApiRequests={() => DownloadTable()}
            postAPI={true}
            pagination
            ApiRequestBody={{
              "category_token": categoriesDet.selectedVal,
              "sub_category_token": subCategoriesDet.selectedVal
            }}
            columns={productsColumn}
            ApiRequest={() => FetchProducts()}
            typography={[
              { type: "title", children: "Products List" },
              { type: "caption", children: "Products" }
            ]}
            isStatusChanged={generateTable}
          />
        </Paper>

        <ViewModal
          modalOpen={modalOpen}
          trigger={setModalOpen}
          modalContent={RenderModalContent}
          actionButtons={RenderCta}
          customStyle={{ width: 400 }}
          title={`Update Stock ${stockUpdateType === 'bulk' ? 'CSV' : ''}`}
        />
      </Box>
    </>
  );
}

export default InventoryManagement