import { createSlice } from "@reduxjs/toolkit";
import secureLocalStorage from "react-secure-storage"

const initialState = {
  isAuthenticated: false,
  mobile: "",
  dialCode: "",
  name: "",
  userName: "",
  email: "",
  value: "",
  type: "",
  dealerCode: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setBearerToken(state, action) {
      secureLocalStorage.setItem("dealerBearerToken", action.payload);
    },
    login(state, action) {
      const { token, name, contact_name, email } = action.payload
      state.isAuthenticated = true;
      secureLocalStorage.setItem("dealerBearerToken", token);
      state.name = name;
      state.userName = contact_name;
      state.email = email;
    },
    setMobileData(state, action) {
      const { mobile, dialCode, value, type, dealerCode } = action.payload
      state.mobile = mobile;
      state.dialCode = dialCode;
      state.value = value;
      state.type = type;
      state.dealerCode = dealerCode;
    },
    resetMobileData(state, action) {
      state.mobile = "";
      state.dialCode = "";
      state.value = "";
    },
    logout(state) {
      state.isAuthenticated = false;
      secureLocalStorage.removeItem("dealerBearerToken");
    },
  },
});
export const authActions = authSlice.actions;
export default authSlice;
