import React, { useEffect, useMemo, useState } from "react"
import classes from "./Searching.module.css"
import CustomeSlecter from "../../../components/UI/Dropdown/CustomeSlecter"
import SearchComponent from "../../../components/UI/ShopnowSearch/Search"
import arrow from "../../../assets/image/svg/dropdown.svg"
import { useDispatch, useSelector } from "react-redux"
import {
  setSearchTerm,
  clearSuggestions,
  clearCategory,
  clearSubCategory,
  setCategory,
  setSubCategory,
  setMaterialValue,
  setFinish,
  setVerticalMaterialValue,
  setVerticalFinish,
  setSizeValue,
  setVerticalSizeValue,
  setSelectedSizeName,
  clearSelectedSizeName,
  clearSizeValue,
} from "../../../services/storeSlice/shopNowSlice"
import useApiHttp from "../../../hooks/ues-http"
import { Autocomplete, TextField } from "@mui/material"
import Fuse from "fuse.js"

// Utility function to debounce
const debounce = (func, wait) => {
  let timeout
  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => func(...args), wait)
  }
}

const Searching = ({ Loading }) => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.shopNow)
  const [metaData, setMetaData] = useState([])
  const [materialsMeta, setMaterialsMeta] = useState([])
  const [coatingsMeta, setCoatingsMeta] = useState([])
  const [sizeList, setSizeList] = useState([])
  // const [selectedCategory, setSelectedCategory] = useState("")
  // const [selectedSubCategory, setSelectedSubCategory] = useState("")
  const [categories, setCategories] = useState([])
  const [subcategories, setSubcategories] = useState([])

  const [materials, setMaterials] = useState([])
  const [coatings, setMCoatings] = useState([])

  const [datas, setDatas] = useState([])
  const [typingValue, setTypingValue] = useState("")
  const [searchResults, setSearchResults] = useState([])

  const {
    isLoading: metaDataLoading,
    success: metaDataSucces,
    error: metaDataError,
    sendRequest: metaDataRequest,
  } = useApiHttp()
  const {
    isLoading: materialsMetaLoading,
    success: materialsMetaSucces,
    error: materialsMetaError,
    sendRequest: materialsMetaRequest,
  } = useApiHttp()
  const {
    isLoading: coatingsMetaLoading,
    success: coatingsMetaSucces,
    error: coatingsMetaError,
    sendRequest: coatingsMetaRequest,
  } = useApiHttp()
  const {
    isLoading: sizeListLoading,
    success: sizeListSucces,
    error: sizeListError,
    sendRequest: sizeListRequest,
  } = useApiHttp()

  useEffect(() => {
    metaDataRequest(
      {
        url: `dealer/shop-now/drop-down`,
      },
      data => {
        setMetaData(data?.data || {}) // Ensure data is safely handled
      }
    )
  }, [])

  useEffect(() => {
    if (data.selectedCategory && data.selectedSubCategory) {
      metaDataRequest(
        {
          url: `dealer/shop-now/material/list`,
          method: "POST",
          body: {
            category_token: data.selectedCategory,
            sub_category_token: data.selectedSubCategory,
          },
        },
        data => {
          setMaterialsMeta(data?.data || {}) // Ensure data is safely handled
        }
      )
    }
  }, [data.selectedCategory, data.selectedSubCategory])
  useEffect(() => {
    if (
      data.selectedCategory &&
      data.selectedSubCategory &&
      data.verticalMaterial
    ) {
      metaDataRequest(
        {
          url: `dealer/shop-now/coating/list`,
          method: "POST",
          body: {
            category_token: data.selectedCategory,
            sub_category_token: data.selectedSubCategory,
            material_token: data.verticalMaterial,
          },
        },
        data => {
          setCoatingsMeta(data?.data || {}) // Ensure data is safely handled
        }
      )
    }
  }, [data.selectedCategory, data.selectedSubCategory, data.verticalMaterial])

  useEffect(() => {
    if (metaData?.categories) {
      setCategories(metaData.categories)
    }
  }, [metaData])

  // Handle category change
  const handleCategoryChange = categoryToken => {
    // setSelectedCategory(categoryToken)
    dispatch(setCategory(categoryToken))
    dispatch(clearSuggestions())
    dispatch(setSubCategory(""))
    dispatch(setVerticalMaterialValue(""))
    dispatch(setVerticalFinish(""))
    dispatch(setVerticalSizeValue(null))
    dispatch(setSelectedSizeName(null))
    // if (categoryToken === "") {
    //   dispatch(clearSuggestions())
    // }

    const category = categories?.find(cat => cat.token === categoryToken)
    if (category) {
      setSubcategories(category.subcategory)
    } else {
      // setSubcategories([])
      dispatch(setSubCategory(""))
      dispatch(setVerticalMaterialValue(""))
      dispatch(setVerticalFinish(""))
      dispatch(setVerticalSizeValue(null))
      dispatch(setSelectedSizeName(null))
    }
    // setSelectedSubCategory("")
  }
  useEffect(() => {
    dispatch(clearSuggestions())
    dispatch(setSubCategory(""))
    dispatch(setVerticalMaterialValue(""))
    dispatch(setVerticalFinish(""))
    dispatch(setVerticalSizeValue(null))
    dispatch(setSelectedSizeName(null))
    dispatch(setCategory(""))
  }, [])
  // Handle subcategory change
  const handleSubCategoryChange = subCategoryToken => {
    // setSelectedSubCategory(subCategoryToken)
    dispatch(setSubCategory(subCategoryToken))
    dispatch(clearSuggestions())
    if (subCategoryToken === "") {
      dispatch(setSubCategory(""))
      dispatch(setVerticalMaterialValue(""))
      dispatch(setVerticalFinish(""))
      dispatch(setVerticalSizeValue(null))
      dispatch(setSelectedSizeName(null))
    }
  }

  // Handle search input change
  const handleSearch = term => {
    if (term !== "") {
      dispatch(setSearchTerm(term))
    }
  }

  // Handle subcategory change
  const handleMaterialChange = material => {
    dispatch(setVerticalMaterialValue(material))
    dispatch(clearSuggestions())
  }

  const handleSubFinishChange = finish => {
    dispatch(setVerticalFinish(finish))
    dispatch(clearSuggestions())
  }

  useEffect(() => {
    if (data.selectedCategory && data.selectedSubCategory) {
      const defaultMaterial = materialsMeta?.find(
        material => material.name === "M2"
      )
      const defaultFinish = coatingsMeta?.find(
        coating => coating.name === "STD"
      )
      const isSelectedMaterialAvailable = data.verticalMaterial
        ? materialsMeta?.find(
            materialObject => materialObject.token === data.verticalMaterial
          )
        : undefined
      const isSelectedFinishAvailable = data.verticalFinish
        ? coatingsMeta?.find(
            coatingObject => coatingObject.token === data.verticalFinish
          )
        : undefined
      if (!data.verticalMaterial && defaultMaterial) {
        dispatch(setVerticalMaterialValue(defaultMaterial.token))
      } else if (data.verticalMaterial && !isSelectedMaterialAvailable) {
        dispatch(setVerticalMaterialValue(""))
      }
      if (!data.verticalFinish && defaultFinish) {
        dispatch(setVerticalFinish(defaultFinish.token))
      } else if (data.verticalFinish && !isSelectedFinishAvailable) {
        dispatch(setVerticalFinish(""))
      }
      dispatch(clearSuggestions())
    } else {
      // Clear values when no category or subcategory is selected
      dispatch(clearSizeValue())
      dispatch(clearSelectedSizeName())
    }
  }, [
    materialsMeta,
    coatingsMeta,
    // data.selectedCategory,
    // data.selectedSubCategory,
    data.verticalMaterial, // Dependency ensures updates happen only when needed
    data.verticalFinish,
    dispatch,
  ])

  useEffect(() => {
    if (data.selectedCategory && data.selectedSubCategory) {
      const defaultMaterial = materialsMeta?.find(
        material => material.name === "M2"
      )
      const defaultFinish = coatingsMeta?.find(
        coating => coating.name === "STD"
      )

      if (!data.verticalMaterial && defaultMaterial) {
        dispatch(setVerticalMaterialValue(defaultMaterial.token))
      }
      if (!data.verticalFinish && defaultFinish) {
        dispatch(setVerticalFinish(defaultFinish.token))
      }
      dispatch(clearSuggestions())
    } else {
      // Clear values when no category or subcategory is selected
      dispatch(clearSizeValue())
      dispatch(clearSelectedSizeName())
    }
  }, [data.selectedCategory, data.selectedSubCategory])

  const sizeListRequestHandle = () => {
    sizeListRequest(
      {
        url: `dealer/shop-now/size/list`,
        method: "POST",
        body: {
          category_token: data.selectedCategory,
          sub_category_token: data.selectedSubCategory,
          material_token: data.verticalMaterial,
          coating_token: data.verticalFinish,
        },
      },
      data => {
        setSizeList(data?.data)
      }
    )
  }

  useEffect(() => {
    if (data.verticalMaterial || data.verticalFinish) {
      sizeListRequestHandle()
    }
  }, [
    data.selectedCategory,
    data.selectedSubCategory,
    data.verticalMaterial,
    data.verticalFinish,
  ])

  // Flatten and initialize size data
  useEffect(() => {
    const flattenedSizes = sizeList.map(item => ({
      token: item.token || "",
      name: item.name || "no data",
    }))
    setDatas(flattenedSizes)
    setSearchResults(flattenedSizes.slice(0, 200)) // Initial 200 items
  }, [sizeList])

  // Initialize Fuse.js for searching
  const fuse = useMemo(() => {
    return new Fuse(datas, {
      keys: ["name"],
      threshold: 0.3,
      shouldSort: true,
    })
  }, [datas])

  // Debounced input change handler
  const handleInputChange = debounce(inputValue => {
    if (inputValue) {
      const results = fuse.search(inputValue).map(result => result.item)
      const strictFilteredResults = results.filter(oldResult =>
        oldResult.name.toLowerCase().startsWith(inputValue.toLowerCase())
      )

      setSearchResults(strictFilteredResults)
    } else {
      setSearchResults(datas.slice(0, 200)) // Reset if cleared
    }
  }, 300)

  useEffect(() => {
    if (
      !data.selectedCategory &&
      !data.selectedSubCategory &&
      !data.verticalMaterial &&
      !data.verticalFinish &&
      !data.selectedSizeName //
    ) {
      dispatch(clearSuggestions())
    }
    if (!data.selectedSizeName) {
      dispatch(clearSuggestions())
    }
  }, [
    data.selectedCategory,
    data.selectedSubCategory,
    data.verticalMaterial,
    data.verticalFinish,
    data.selectedSizeName,
  ])

  return (
    <>
      <div className={classes.container}>
        <div className={classes.selecter}>
          <CustomeSlecter
            data={
              categories?.length > 0
                ? categories.map(category => ({
                    label: category.name,
                    value: category.token,
                  }))
                : [] // Provide an empty array as fallback
            }
            title={"Category"}
            width={160}
            value={data.selectedCategory}
            onChange={e => handleCategoryChange(e.target.value)}
          />
        </div>
        <div className={classes.filters}></div>
        <div className={classes.selecter}>
          <CustomeSlecter
            data={
              subcategories?.length > 0
                ? subcategories.map(sub => ({
                    label: sub.name,
                    value: sub.token,
                  }))
                : [] // Provide an empty array as fallback
            }
            title={"Sub-Category"}
            width={200}
            value={data.selectedSubCategory}
            disabled={!data.selectedCategory}
            onChange={e => handleSubCategoryChange(e.target.value)}
          />
        </div>
        <div className={classes.filters}></div>
        <div className={classes.selecter}>
          <CustomeSlecter
            data={
              materialsMeta?.length > 0
                ? materialsMeta.map(sub => ({
                    label: sub.name,
                    value: sub.token,
                  }))
                : [] // Provide an empty array as fallback
            }
            title={"Material"}
            width={160}
            value={data.verticalMaterial}
            onChange={e => handleMaterialChange(e.target.value)}
            disabled={!data.selectedCategory || !data.selectedSubCategory}
          />
        </div>
        <div className={classes.filters}></div>
        <div className={classes.selecter}>
          <CustomeSlecter
            data={
              coatingsMeta?.length > 0
                ? coatingsMeta.map(sub => ({
                    label: sub.name,
                    value: sub.token,
                  }))
                : [] // Provide an empty array as fallback
            }
            title={"Variant"}
            width={160}
            value={data.verticalFinish}
            onChange={e => handleSubFinishChange(e.target.value)}
            disabled={
              !data.selectedCategory ||
              !data.selectedSubCategory ||
              !data.verticalMaterial
            }
          />
        </div>
        <div className={classes.filters}></div>
        <div className={`${classes.search_box}`}>
          {/* <SearchComponent
          placeholder="Search"
          fullWidth={true}
          value={data.searchTerm}
          onChange={e => handleSearch(e.target.value)}
        /> */}
          <Autocomplete
            disablePortal
            options={searchResults}
            loading={sizeListLoading}
            getOptionLabel={option => option.name}
            isOptionEqualToValue={(option, value) =>
              option.token === value.token
            }
            value={data.selectedSizeName}
            onChange={(event, newValue) => {
              dispatch(setVerticalSizeValue(newValue?.token || null))
              dispatch(setSelectedSizeName(newValue))
            }}
            onInputChange={(event, inputValue) => {
              setTypingValue(inputValue)
              handleInputChange(inputValue)
            }}
            ListboxProps={{
              className: "custom-autocomplete-listbox", // Add a custom class
            }}
            renderInput={params => (
              <TextField
                {...params}
                label={!data.selectedSizeName && !typingValue ? "Size" : ""}
                InputLabelProps={{
                  shrink: false,
                  style: {
                    fontSize:
                      data.selectedSizeName || typingValue ? "0px" : "14px",
                    color: "#363636",
                    fontFamily: "var(--font-regular)",
                  },
                }}
                disabled={
                  !data.selectedCategory ||
                  !data.selectedSubCategory ||
                  !data.verticalMaterial ||
                  !data.verticalFinish
                }
              />
            )}
            sx={{
              fontSize: "14px",
              color: "#363636",
              fontFamily: "var(--font-Medium)",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              boxShadow: "none",
              opacity:
                !data.selectedCategory ||
                !data.selectedSubCategory ||
                !data.verticalMaterial ||
                !data.verticalFinish
                  ? 0.5
                  : 1,
              pointerEvents:
                !data.selectedCategory ||
                !data.selectedSubCategory ||
                !data.verticalMaterial ||
                !data.verticalFinish
                  ? "none"
                  : "auto",
              cursor:
                !data.selectedCategory ||
                !data.selectedSubCategory ||
                !data.verticalMaterial ||
                !data.verticalFinish
                  ? "not-allowed"
                  : "pointer",
            }}
          />
        </div>
      </div>
    </>
  )
}

export default Searching
